<template>
  <div>全部方案</div>
</template>

<script>
export default {
  name: 'all-plan'
}
</script>

<style scoped>

</style>
